import React from 'react'
import {
  Container,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'

const videoId = 'Mfhl-Fqpv2s'

const FHIRWebinarWatchPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>
      
      <Section className="pt-16 pb-8 bg-white">
        <Container className="container-md">
          <div className="flex flex-col items-center">
            <h1 className="mb-12 text-5xl text-center">
              Playing with FHIR: Hacking and Securing FHIR APIs
            </h1>
            <h2 className="mt-0 mb-12 text-gray-800">28 October 2021</h2>
            <p>
              Alissa Knight's research uncovered that precisely NONE of the
              mobile healthcare apps tested had protection against
              woman-in-the-middle attacks. She was able to intercept credentials
              and steal/manipulate confidential patient data. In our free
              webinar replay, Alissa answers your questions.
            </p>

            <div className="flex items-center justify-center w-full">
              <div className="w-full border border-gray-300 aspect-w-16 aspect-h-9">
                <iframe
                  title="Approov Your APIs"
                  src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <p>
              Approov can help your business secure its mobile APIs quickly and
              effectively. Want to learn more about how we helped dozens of
              companies ensure that only their mobile apps can use their APIs?
              Talk to us.
            </p>

            <LinkButton
              className="button-secondary button-lg"
              href="/product/consult"
            >
              Talk to an Expert
            </LinkButton>
          </div>
        </Container>
      </Section>
    </LandingPageLayout>
  )
}

export default FHIRWebinarWatchPage
